*{
    outline: none !important;
}

body{
    background-color:#fff;
    
}

a, a:hover{
    text-decoration: none !important;
    color:inherit;
}

@media (max-width:576px){
    body{
        margin-top:56px;
    }
    .dicasb{
        display: none;
    }
    .MuiMenu-paper{
        position: fixed !important;
        top:auto !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        max-width: 100% !important;
        border-radius: 0 !important;
        
    }

    .MuiMenu-paper ~ div::before{
         content:"";
         z-index: -1;
         position: fixed;
         inset: 0px;
         background-color:rgba(0,0,0,0.5);

    }

    .txtbtnmin{
        font-size:9px;
    }
    
   
}

.header {
    border-bottom:2px solid #229ED9;
   
   
}
.haderfundo{
    background-color: #2aabee;
}

.header .nav-link{
    font-weight: 600;
    color:#fff !important;
    padding:28px 1.5rem !important;
    border-bottom: 2px solid transparent;
    border:0;
}
.header .nav-link:hover{
    border-bottom: 2px solid #F12C4C;
}

.header .nav-link .icon-lg{
    font-size: 22px;
}

.header .dropdown:hover > .dropdown-menu{
    display: block;
}

.header .dropdown-menu{
    box-shadow: rgba(0,0,0,0.08) 0px 4px 16px 0px, rgba(0,0,0,0.06) 0px 2px 6px 0px;
    border: 1px solid #eee;
    top:80%;
}

.header .dropdown-item{
    padding:10px 30px;
    font-weight: 600;
    color:#444;

}


.home .header{
    background-color: #1E88E5 !important;
}
.home .header .nav-link{
    color:#fff !important;
}

.logo-mobile{
    max-height: 50px;
}

.menu-mobile .MuiListItemText-primary{
    font-weight: 600;
    color:#444;

}
.menu-mobile svg{
    font-size: 24px;
    color:#444;
}

.label-custom{
    color:#2c2c31;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
}

.card{
    background-color:rgb(255,255,255);
    box-shadow: rgba(153, 153, 153, 0.2) 0px 2px 4px 0px;
    border-radius: 3px;
    border:none;
}

.tips{
    position:sticky;
    top:20px;
}
.btn-save{
    position: fixed;
    bottom:30px;
}


.MuiMenuItem-root{
    padding-top: 9px !important;
    padding-bottom: 9px !important;
    font-weight: 500 !important;

}

.tabelaPagination .MuiInputBase-fullWidth{
    width: 10% !important;
}

.pdf-container-grid{
    display: grid;
    grid-column-gap: 50px;
}


.flexd{
 
        display: flex;
        min-height: 100vh;
        flex-direction: column;
     
}

.MuiTypography-root{
    color:'#fff'
}


.MuiAppBar-colorPrimary{
    color:#ccc !important;
    background-color: #2AABEE !important;
}

.MuiList-padding {
    padding-top:0px !important;
  }